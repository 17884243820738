<template>
    <div class="card card-height">
        <div class="table-responsive">
            <table class="table custom-table-padding">
                <thead>
                    <tr>
                        <th>Voucher Date</th>
                        <th>Voucher No</th>
                        <th>Bill Date</th>
                        <th>Bill Number</th>
                        <th>Party Name</th>
                        <th>Accounting Head</th>
                        <th>Base Amount</th>
                        <th>VAT</th>
                        <th>Amount</th>
                        <th>Total Bill Amount</th>
                        <th>VDS Type</th>
                        <th>HS / Service Code</th>
                        <th>VDS Section Head</th>
                        <th>VDS  Rate</th>
                        <th>VDS Amount</th>
                        <th>TDS Type</th>
                        <th>Section Ref</th>
                        <th>TDS Section Head</th>
                        <th>Tax Rate</th>
                        <th>TDS Amount</th>
                        <th>Net payment to supplier</th>
                        <th>Payment Status</th>
                        <th>Note</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
        <p v-if="!tableItems.length" class="text-center mt-5">No data</p>
    </div>
</div>
</template>

<script setup>
import { useRoute, useRouter } from "vue-router";

const route = useRoute();
const router = useRouter();

const props = defineProps({
    tableItems: {
        type: Array,
        default: []
    }
})
</script>

<style scoped>
.custom-table-padding {
    width: 210%
}
</style>