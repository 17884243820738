<template>
    <div class="card">
        <div class="bg-blue-light p-1">
            <TitleButton 
                :showBtn="false"
                title="TDS VDS Register"
            />
        </div>
        <div class="my-2 px-2">
            <DateQuerySetter
                btnTitle="Go"
                @onClickGo="onClickToGo"
                class="tds-vds-query-setter"
            >
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-1">
                    <label for="colFormLabel" class="col-form-label">Party Name</label>
                    <v-select
                        placeholder="Select Party Name"
                        :options="[]"
                        :reduce="item => item.value"
                        label="name"
                    />
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-1">
                    <label for="colFormLabel" class="col-form-label">Accounting Head</label>
                    <v-select
                        placeholder="Select Accounting Head"
                        :options="[]"
                        :reduce="item => item.value"
                        label="name"
                    />
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-1">
                    <label for="colFormLabel" class="col-form-label">VDS Section Head</label>
                    <v-select
                        placeholder="Select VDS Section Head"
                        :options="[]"
                        :reduce="item => item.value"
                        label="name"
                    />
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-1">
                    <label for="colFormLabel" class="col-form-label">TDS Section Head</label>
                    <v-select
                        placeholder="Select TDS Section Head"
                        :options="[]"
                        :reduce="item => item.value"
                        label="name"
                    />
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-1">
                    <label for="colFormLabel" class="col-form-label">Payment Status</label>
                    <v-select
                        placeholder="Select Payment Status"
                        :options="[]"
                        :reduce="item => item.value"
                        label="name"
                    />
                </div>
            </DateQuerySetter>
        </div>

        <div class="col-12 px-2 mt-2 mb-4">
            <ListTable 
                :tableItems="tableItems"
            />
        </div>

        <div class="px-2 position-absolute bottom-0 mt-4">
            <Pagination :offset="params.offset" @onPageChange="onPageChange" ref="pagination"/>
        </div>

        <Loader v-if="loading"/>
    </div>
</template>

<script setup>
import TitleButton from '@/components/atom/TitleButton';
import { inject, onMounted, reactive, ref } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import Loader from '@/components/atom/LoaderComponent'
import Pagination from '@/components/atom/Pagination'
import ListTable from '@/components/molecule/company/compliance/tds-vds-register/TdsVdsRegisterTable.vue';
import DateQuerySetter from '@/components/atom/DateQuerySetter.vue';
import handleCrm from '@/services/modules/crm/crm.js'

const store = useStore();
const route = useRoute();
const router = useRouter();
const showError = inject('showError');
const showSuccess = inject('showSuccess');
const loading = ref(false)
const tableItems = ref([]);
const params = ref({
    searchTxt: '',
    offset: 10
});

const onClickToGo = () => {
    //TODO:: Some Logic Will go here
}

const setPaginationData = (data) => {
    store.commit('setPaginationData', data)
};

const setPagination = (data) => {
    setPaginationData({
        records: data.total,
        perPage: data.per_page,
        currentPage: data.current_page,
    })
}

const onPageChange = async (page) => {
    let routeQuery = Object.assign({}, route.query)
    routeQuery.page = page
    await router.push({path: route.path, query: routeQuery})
    getTdsVdsList();
}

const getTdsVdsList = async () => {

    const query = getQuery();
    
    //TODO:: Call API Method
}

const getQuery = () => {
    let query = `?company_id=${route.params.companyId}`;
    if (route.query.page) query += '&page=' + route.query.page
    if(params.searchTxt) query += `&search_txt=${params.searchTxt}`;
    query += '&offset=' + params.offset
    return query;
}
</script>

<style>
.tds-vds-query-setter .action-btn-setter {
    margin-bottom: 1.5% !important;
}
</style>